.fade {
  @include transition($transition-fade);

  &:not(.show) {
    opacity: 0;
  }
}

.collapse {
  &:not(.show) {
    display: none;
  }
}

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  @include transition($transition-collapse);
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.fade-in {
  animation-name: fadeIn;
  animation-duration: 1s;
}

//
// Components
//

.c-wrapper {
  will-change: auto;
  transition: transform $layout-transition-speed, margin-left $layout-transition-speed, margin-right $layout-transition-speed, width $layout-transition-speed, flex $layout-transition-speed;
}

.c-sidebar {
  will-change: auto;
  transition: transform $layout-transition-speed, margin-left $layout-transition-speed, margin-right $layout-transition-speed, width $layout-transition-speed, z-index 0s ease $layout-transition-speed;
  &.c-sidebar-unfoldable {
    transition: transform $layout-transition-speed, margin-left $layout-transition-speed, margin-right $layout-transition-speed, width $layout-transition-speed, z-index 0s ease 0s;
  }
}
